import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwamerchantservices.com/wp-content/uploads/2015/08/Square_SwipingHands.jpg"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "253px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "119%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB3z0wrUCozLemEP/EABoQAAMBAQEBAAAAAAAAAAAAAAECAwAEEhP/2gAIAQEAAQUCt0mbirsRTWQaUz6+K653MtPW/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQITH/2gAIAQMBAT8BRQ8j/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8BdGij/8QAHxABAAEEAQUAAAAAAAAAAAAAAQACEBESAyExMkFR/9oACAEBAAY/AtCnLO5Os3PP0zHLhz8LITasAt//xAAeEAACAgICAwAAAAAAAAAAAAABEQAhMUEQUWFxgf/aAAgBAQABPyHEWdwBx7DSiRRnxFCEDEmoCbGwKIT9j7GiwVXuYdCkXx//2gAMAwEAAgADAAAAEAwnAP/EABkRAQACAwAAAAAAAAAAAAAAAAEAEBEhUf/aAAgBAwEBPxBo7meYQCV//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERECH/2gAIAQIBAT8QsuEDVtZ//8QAHxABAQACAQQDAAAAAAAAAAAAAREAMSEQQVFhcZGh/9oACAEBAAE/EEQJEXy9TN8jmxegODCB3W8LAwEtWD+5KfdUN5uOU+Iw8sICsEN39ZW462pvT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Square_SwipingHands",
            "title": "Square_SwipingHands",
            "src": "/static/5a69ae34d84ae50e8e1ce75704166f42/99579/Square_SwipingHands-253x300.jpg",
            "srcSet": ["/static/5a69ae34d84ae50e8e1ce75704166f42/8675c/Square_SwipingHands-253x300.jpg 100w", "/static/5a69ae34d84ae50e8e1ce75704166f42/3cc80/Square_SwipingHands-253x300.jpg 200w", "/static/5a69ae34d84ae50e8e1ce75704166f42/99579/Square_SwipingHands-253x300.jpg 253w"],
            "sizes": "(max-width: 253px) 100vw, 253px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`The mobile point of sale market already seems overcrowded, but what's out there now is just a taste of what's to come, according to 451 Research.`}</p>
    <p>{`The global installed base of mobile point of sale technology will grow to 54.03 million units in 2019 from 13.3 million units this year. That's a fourfold increase and a 32% compound annual growth rate.`}</p>
    <p>{`The factors driving the growth are diverse and cover a range of geographies and industries. In emerging markets, mobile point of sale terminals promote financial inclusion. In more developed economies, the technology is a line-buster at retailers and helps overall engagement.`}</p>
    <p>{`"We think of mPOS as a fairly well penetrated technology, but we've only scratched the surface," said Jordan McKee, a senior analyst covering mobile payments for 451 Research.`}</p>
    <p>{`In North America, larger businesses with more than 1,000 employees are increasingly deploying mobile point of sale technology, and 87% of IT decision makers say that accepting card payments on a mobile device and providing a real-time receipt is important, according the research.`}</p>
    <p>{`Please contact CWA for more details on Mobile POS options.  (866) 210-4625 X 1 or email `}<a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a>{`.`}</p>
    <p>{`For the rest of this article, visit Payments Source.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      